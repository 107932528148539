import styled from "styled-components";
import Link from "next/link";
import { routePaths } from "@shared/routes";
import { Typography } from "@shared/ui/Typography";
import { useTranslation } from "next-i18next";
import { Button } from "@shared/ui/Button";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export default function FourOhFour() {
  const { t } = useTranslation();

  return (
    <StyledView>
      <StyledContent>
        <StyledTitle tag="h1" weight={700}>
          404
        </StyledTitle>
        <StyledSubtitle tag="p" fontSize="2rem">
          {t(`К сожалению, запрашиваемая страница не найдена.`)}
        </StyledSubtitle>
        <StyledLink href={routePaths.main.index}>
          <Button>{t(`Вернуться на главную`)}</Button>
        </StyledLink>
      </StyledContent>
    </StyledView>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}

const StyledView = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary_light_gray}1A;
`;

const StyledContent = styled.div`
  max-width: 54.4rem;
  margin: 0 auto;
  display: grid;
  padding-block: 2.4rem;
  padding-inline: 1.6rem;
`;

const StyledTitle = styled(Typography)`
  font-size: 14.4rem;
  margin-bottom: 1.6rem;
  text-align: center;
`;

const StyledSubtitle = styled(Typography)`
  margin-bottom: 4.8rem;
  text-align: center;
`;

const StyledLink = styled(Link)`
  justify-self: center;
`;
